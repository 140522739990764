@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Inter;
  src:
    url('../fonts/Inter-ExtraLight.woff2') format('woff2'),
    url('../fonts/Inter-ExtraLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src:
    url('../fonts/Inter-Regular.woff2') format('woff2'),
    url('../fonts/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src:
    url('../fonts/Inter-SemiBold.woff2') format('woff2'),
    url('../fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
    appearance: none;
  }
}

.Toastify__toast {
  transition-property: background-color;
  transition-duration: 1500ms;
  transition-timing-function: ease-in-out;
  background-color: #fedb7c33;
}

.Toastify__toast--success {
  background: #6ce9a633;
}

.Toastify__toast--error {
  background-color: #fda29b33 !important;
}

svg {
  width: 100%;
  height: auto;
}
